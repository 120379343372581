<template>
    <div>
        <v-row justify="space-between">
            <v-col cols="12" md="6">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-expansion-panels v-model="panel" multiple>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                차량정보
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row justify="space-between">
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            v-model.number="forms.info.cc"
                                            type="number"
                                            
                                            label="배기량*"
                                            suffix="CC"
                                            @input="carTypeChange"
                                        ></v-text-field>
                                        
                                        <v-text-field
                                            v-model.number="forms.info.carPrice"
                                            type="number"
                                           
                                            label="차량기본가격*"
                                            required
                                            suffix="원"
                                            @input="handleChange"
                                        ></v-text-field>

                                        <v-select
                                            v-model="forms.info.deliveryConditions"
                                            :items="formData.deliveryConditions"
                                            item-text="text"
                                            item-value="value"
                                            label="출고조건선택"
                                        ></v-select>
                                        <v-text-field
                                            v-if="forms.info.deliveryConditions==='본사출고'"
                                            v-model.number="forms.info.headOfficePrice"
                                            type="number"
                                            label="본사출고조건"

                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-select
                                            v-model="forms.info.carType"
                                            :items="formData.carType"
                                            item-text="text"
                                            item-value="value"
                                            label="차종"

                                            v-on:change="carTypeChange"
                                        ></v-select>
                                        <v-text-field
                                            v-model.number="forms.info.optionAmount"
                                            type="number"
                                            label="옵션+색상가"
                                            @input="handleChange"
                                            suffix="원"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model.number="forms.info.discountAmount"
                                            type="number"
                                            label="할인금액"
                                            @input="handleChange"
                                            suffix="원"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model.number="forms.info.personalConsumptionTax"
                                            type="number"
                                            label="개별소비세인하액"
                                            @input="handleChange"
                                            suffix="원"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-alert
                                    dense
                                    outlined
                                    type="error"
                                    >
                                    대리점출고,본사출고등에 따른 금액변동이 있으나 테스트에서는 생략
                                    </v-alert>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                탁송료
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row justify="space-between">

                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            v-model.number="forms.consignment.amount"
                                            type="number"
                                            label="제조사탁송료"
                                            @input="handleChange"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-alert
                                    dense
                                    outlined
                                    type="error"
                                    >
                                    인도지역,탁송처리방식 변경에 따른 제조사탁송료가 변동할수 있으나 테스트에서는 생략하고 제조사탁송료 직접입력하세요
                                    </v-alert>

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                공과금항목
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row justify="space-between">
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            
                                            
                                            label="공채할인지역"
                                            value="경기"
                                            readOnly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        
                                        <v-text-field
                                            v-model.number="forms.dues.publicBondPurchaseRate"
                                            type="number"
                                            label="공채할인율"
                                            @input="handleChange"
                                            suffix="%"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model.number="forms.vehicle.car.outherAmount"
                                            type="number"
                                            label="부대비용포함체크값"
                                            persistent-hint
                                            hint="엑셀에서 해당값입력"
                                        ></v-text-field>
                                       
                                    </v-col>
                                </v-row>
                                <v-alert
                                    dense
                                    outlined
                                    type="error"
                                    >
                                    ** 부대비용포함(체크된상태)은 엑셀파일의 신한리스국산에 해당값을 입력
                                    
                                    </v-alert>
                                <v-alert
                                    dense
                                    outlined
                                    type="error"
                                    >
                                    공채할인지역:경기로 고정(지역에따라 금액변동)<br />
                                    공채가격은 차종,배기량 입력 필수
                                    </v-alert>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        
                    </v-expansion-panels>
                </v-form>
            </v-col>
            <v-col cols="12" md="6">
                <div>
                    본 테스트는 리스원금까지만 산출하도록 처리되었습니다<br />
                    월리스료 및 다른항목의 100% 자동화 처리하기 위해서는<br />
                    기본 차량정보에 탁송료, 공채등 방대한 양의 데이터가 세팅이 되어야 하는 관계로<br />
                    짧은시간안에 처리하기는 어렵습니다<br />
                    * 상단 차량정보 카테고리는 무시해도 됩니다
                </div>
                <br />
                <v-divider />
                <div>
                    <span v-for="(item,i) in resultList" :key="i">{{item}}<br /></span>
                </div>
            </v-col>
        </v-row>
       
    </div>
</template>
<script>
  export default {
    data () {
        return {
            panel: [0, 1, 2],
            valid: true,
            rules: {
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                nameRules: [
                    v => !!v || 'Name is required',
                    v => (v && v.length <= 10) || 'Name must be less than 10 characters',
                ],
            },
            formData: {
                deliveryConditions:['대리점출고'],//], '본사출고'],
                consignmentProcessingMethod:['제조사(딜러탁송]','외주탁송','복합탁송'],
                area: ['경기'],
                carType: [
                    {text:'승용',value:0},
                    {text:'RV(5인이하)',value:1},
                    {text:'RV(7-10인)',value:2},
                    {text:'RV(11인이상)',value:3},
                    {text:'승합',value:4},
                    {text:'화물(1톤이하)',value:5},
                    {text:'화물(2톤이하)',value:6},
                    //{text:'화물(3톤이하)',value:7},
                ],
                
            },
            publicBondPurchaseRateData:[//공공채권 매입율
                { area:'서울', kind: '승용', cc1000: 0.09, cc1500: 0.09, cc1600: 0.12, cc2000:0.2 ,percent: true },
                { area:'서울', kind: 'RV(5인이하)', cc1000: 0.09, cc1500: 0.09, cc1600: 0.12, cc2000:0.2 ,percent: true }
            ],
            publicBondPurchaseRateData2:[//공공채권 매입율
                    {
                        area: '경기', kind: '승용',number: 2,
                        cc: [
                            { percent: true, cc: 1000, value: 0.06 },
                            { percent: true, cc: 1500, value: 0.06 },
                            { percent: true, cc: 1600, value: 0.08 },
                            { percent: true, cc: 2000, value: 0.12 },
                        ]
                    },
                    {
                        area: '경기', kind: 'RV(5인이하)',number: 3,
                        cc: [
                            { percent: true, cc: 1000, value: 0.06 },
                            { percent: true, cc: 1500, value: 0.06 },
                            { percent: true, cc: 1600, value: 0.08 },
                            { percent: true, cc: 2000, value: 0.12 },
                        ]
                    },
                    {
                        area: '경기', kind: 'RV(7-10인)',number: 4,
                        cc: [
                            { percent: true, cc: 1000, value: 0.06 },
                            { percent: true, cc: 1500, value: 0.06 },
                            { percent: true, cc: 1600, value: 0.08 },
                            { percent: true, cc: 2000, value: 0.12 },
                        ]
                    },
                    {
                        area: '경기', kind: 'RV(11인이상)',number: 4,
                        cc: [
                            { percent: true, cc: 1000, value: 0.03 },
                            { percent: true, cc: 1500, value: 0.03 },
                            { percent: true, cc: 1600, value: 0.03 },
                            { percent: true, cc: 2000, value: 0.03 },
                        ]
                    },
                    {
                        area: '경기', kind: '승합',number: 4,
                        cc: [
                            { percent: true, cc: 1000, value: 0.03 },
                            { percent: true, cc: 1500, value: 0.03 },
                            { percent: true, cc: 1600, value: 0.03 },
                            { percent: true, cc: 2000, value: 0.03 },
                        ]
                    },
                    {
                        area: '경기', kind: '화물(1톤이하)',number: 4,
                        cc: [
                            { percent: true, cc: 1000, value: 0.15 },
                            { percent: true, cc: 1500, value: 0.15 },
                            { percent: true, cc: 1600, value: 0.15 },
                            { percent: true, cc: 2000, value: 0.15 },
                        ]
                    },
                    {
                        area: '경기', kind: '화물(2톤이하)',number: 4,
                        cc: [
                            { percent: true, cc: 1000, value: 0.03 },
                            { percent: true, cc: 1500, value: 0.03 },
                            { percent: true, cc: 1600, value: 0.03 },
                            { percent: true, cc: 2000, value: 0.03 },
                        ]
                    }
                ],
            forms: {
                info: {
                    carType: 0,//차종
                    cc:999,//배기량
                    carPrice: 0,//차량가격
                    deliveryConditions:'대리점출고',//출고조건
                    headOfficePrice:0,//본사출고조건
                    optionAmount: 0,//옵션가
                    discountAmount:0,//할인금액
                    personalConsumptionTax:0,//개별소비세 인하액

                },
                consignment: {
                    processingMethod: '제조사(딜러탁송)',//탁송료처리방식
                    area:'',//인도지역
                    amount: 119000,//탁송료
                },
                dues: {//공과금
                    publicBondPurchaseRate: 12,//공채할인율-입력자료
                },
                vehicle: {
                    car: {
                        totalAmount:0, //최종차량가격
                        supplyPrice:0, //공급가격
                        vta:0,//부가세
                        //consignmentFee: 119000,//제조사탁송료
                        //publicBondPurchaseRate: 12,//공채할인율

                        acquisitionTaxRate:0.07,//bq7: 0.07,//취득세율
                        acquisitionTax: 0,//취득세
                        publicBondPurchase: 0,//공채가격
                        privateBondPurchaseRate: 0.12,//공채할인율-내부자료

                        

                        fuelCode: 'M',

                        outherAmount: 0,//239100,//부대비용 //todo
            
                        advancePayment: 0,//선수금 //todo
                        leasePrincipal: 0,//리스원금 = 계산서가격합계 + 취득세 + 공채 + 부대비용
                        acquisitionCost: 0, // 취득원가 = 리스원금 + 선수금


                    },
                    bill: { // 계산서가격
                        totalAmount:0, //최종차량가격
                        supplyPrice:0, //공급가격
                        vta:0,//부가세
                    },

                }
            },
            resultList: [

            ]
            
       
        }
    },
    methods: {
        carTypeChange() {
            const val =  this.publicBondPurchaseRateData[this.forms.info.carType]
            this.forms.vehicle.car.privateBondPurchaseRate = 0
            
            if (this.forms.info.cc>0 && this.forms.info.cc < 1000) {
                
                this.forms.vehicle.car.privateBondPurchaseRate = val.cc[0].value;
            } else if (this.forms.info.cc>=1000 && this.forms.info.cc < 1500) {
                this.forms.vehicle.car.privateBondPurchaseRate = val.cc[1].value;
            } else if (this.forms.info.cc>=1500 && this.forms.info.cc < 1600) {
                this.forms.vehicle.car.privateBondPurchaseRate = val.cc[2].value;
            } else if (this.forms.info.cc>=1600) {
                this.forms.vehicle.car.privateBondPurchaseRate = val.cc[3].value;
            } else {
                this.forms.vehicle.car.privateBondPurchaseRate = 0;
            }
            this.$log.warn('공채할인율-내부자료',  this.forms.vehicle.car.privateBondPurchaseRate);
            this.handleChange();
        },
        handleChange() {
            this.resultList = [];
            this.resultList.push('-----------------차량가격-------------------------')

            this.forms.vehicle.car.totalAmount = this.forms.info.carPrice + this.forms.info.optionAmount - this.forms.info.discountAmount - this.forms.info.personalConsumptionTax;
            this.forms.vehicle.car.supplyPrice = Math.round(this.forms.vehicle.car.totalAmount/1.1);//공급가격
            this.forms.vehicle.car.vta = this.forms.vehicle.car.totalAmount-this.forms.vehicle.car.supplyPrice;//부가세

            this.resultList.push('최종차량가격:' + this.forms.vehicle.car.totalAmount + '원(차량기본가+옵션+색상가-할인가-개소세인하액)');
            this.resultList.push('공급가:' + this.forms.vehicle.car.supplyPrice);
            this.resultList.push('부가세:' + this.forms.vehicle.car.vta);

            this.resultList.push('-----------------계산서가격-------------------------')
            this.forms.vehicle.bill.totalAmount = this.forms.vehicle.car.supplyPrice + this.forms.vehicle.car.vta + this.forms.consignment.amount;//제조사탁송료
            this.forms.vehicle.bill.supplyPrice = Math.round(this.forms.vehicle.bill.totalAmount/1.1);//공급가격
            this.forms.vehicle.bill.vta = this.forms.vehicle.bill.totalAmount-this.forms.vehicle.bill.supplyPrice;//부가세


            this.resultList.push('차량가격:'+this.forms.vehicle.bill.totalAmount + '(탁송료포함됨)');
            this.resultList.push('공급가격:'+this.forms.vehicle.bill.supplyPrice);
            this.resultList.push('부가세:'+this.forms.vehicle.bill.vta);

            //취득세
           
            this.resultList.push('-----------------취득세/공채가격-------------------------')

            //취득세 계산
            this.forms.vehicle.car.acquisitionTax = this.forms.vehicle.bill.supplyPrice * this.forms.vehicle.car.acquisitionTaxRate;
           
            this.forms.vehicle.car.acquisitionTax = Math.floor(this.forms.vehicle.car.acquisitionTax / 10) * 10; //원단위 버림
            this.resultList.push('*취득세:' + this.forms.vehicle.car.acquisitionTax)
            //공채1=계산서가격*공채할인율-내부자료 * (공채할인율%)
            this.forms.vehicle.car.publicBondPurchase = this.forms.vehicle.bill.supplyPrice * this.forms.vehicle.car.privateBondPurchaseRate * (this.forms.dues.publicBondPurchaseRate * 0.01)
            //공채2=원단위올림
            this.forms.vehicle.car.publicBondPurchase = Math.ceil(this.forms.vehicle.car.publicBondPurchase / 10) * 10;//원단위 올림
            this.resultList.push('*공채:' + this.forms.vehicle.car.publicBondPurchase)

            this.resultList.push('부대비용:' + this.forms.vehicle.car.outherAmount);
            //리스원금=계산서가격합계 + 취득세 + 공채 + 부대비용
            //취득원가 = 리스원금 + 선수금
            this.resultList.push('-----------------리스원금/취득원가-------------------------')
            this.forms.vehicle.car.leasePrincipal = this.forms.vehicle.bill.totalAmount + this.forms.vehicle.car.acquisitionTax + this.forms.vehicle.car.publicBondPurchase + this.forms.vehicle.car.outherAmount + 5000;
             this.resultList.push('*리스원금:' + this.forms.vehicle.car.leasePrincipal);
            this.forms.vehicle.car.acquisitionCost = this.forms.vehicle.car.leasePrincipal + this.forms.vehicle.car.advancePayment
           
            this.resultList.push('*리스이용금액(취득원가):' + this.forms.vehicle.car.acquisitionCost);

            this.$log.warn('-------------------------------------')

            /*
            const rprice2 = this.forms.info.carPrice;
            this.result.vehiclePrice.totalAmount = rprice2;//차량가격
            this.result.vehiclePrice.supplyPrice = Math.round(rprice2/1.1);//공급가격
            this.result.vehiclePrice.vta = this.result.vehiclePrice.totalAmount-this.result.vehiclePrice.supplyPrice;//부가세
                
                this.resultList.push('-----------------차량가격-------------------------')

            //계산서가
            this.result.billPrice.totalAmount = this.result.vehiclePrice.supplyPrice + this.result.vehiclePrice.vta + this.result.vehiclePrice.consignmentFee;//차량가격
            this.result.billPrice.supplyPrice = Math.round(this.result.billPrice.totalAmount/1.1);//공급가격
            this.result.billPrice.vta = this.result.billPrice.totalAmount-this.result.billPrice.supplyPrice;//부가세

            this.$log.warn('-------------차량가격------------------------')
            this.$log.warn('차량가격',this.result.vehiclePrice.totalAmount);
            this.$log.warn('공급가격',this.result.vehiclePrice.supplyPrice, '---과세반영', this.result.billPrice.supplyPrice);
            this.$log.warn('부가세',this.result.vehiclePrice.vta);
            this.$log.warn('제조사탁송료',this.result.vehiclePrice.consignmentFee);
            this.$log.warn('-------------계산서가------------------------')
            this.$log.warn('차량가격',this.result.billPrice.totalAmount);
            this.$log.warn('공급가격',this.result.billPrice.supplyPrice);
            this.$log.warn('부가세',this.result.billPrice.vta);
            this.$log.warn('result', this.result);
            this.$log.warn('-------------------------------------')
            */

        }
    }
  }
</script>