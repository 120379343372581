<template>
    <div>
        <v-row justify="space-between">
            
            <v-col cols="12" md="6">

                <v-list >
                    <v-list-item>
                        <el-radio-group v-model="forms.trimcode">
                            <el-radio-button label="78631">아반떼</el-radio-button>
                            <el-radio-button label="75112">쏘렌토</el-radio-button>
                        </el-radio-group>
                    </v-list-item>
                    <v-list-item>
                        <el-radio-group v-model="forms.ProdType">
                            <el-radio-button label="R">렌트</el-radio-button>
                            <el-radio-button label="L">리스</el-radio-button>
                        </el-radio-group>
                    </v-list-item>
                    <v-list-item>
                        <el-radio-group v-model="forms.Period">
                            <el-radio-button label="36" >36개월</el-radio-button>
                            <el-radio-button label="48" >48개월</el-radio-button>
                            <el-radio-button label="60" >60개월</el-radio-button>

                        </el-radio-group>
                    </v-list-item>
                    <v-list-item>
                        <el-radio-group v-model="forms.PriceType">
                            <el-radio-button label="3">보증금 30%</el-radio-button>
                            <el-radio-button label="2">선납금 30%</el-radio-button>
                            <el-radio-button label="1">0%</el-radio-button>
                        </el-radio-group>
                    </v-list-item>
                </v-list>
                <v-divider />
                <v-list  v-for="(item,index) in CAR[forms.trimcode][forms.ProdType + forms.Period + forms.PriceType].items" :key="index">
                    <v-subheader>{{item.title}}</v-subheader>
                    <v-list-item  v-for="(elem,i) in item.items" :key="i">
                        
                        
                            
                                <v-list-item-content>

                                <v-list-item-title>{{elem.name}}</v-list-item-title>
                                <v-list-item-subtitle >{{elem.amount}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{elem.price}}
                                </v-list-item-action>
                            
                        
                    </v-list-item>
                    <v-divider />

                </v-list>
            </v-col>
            <v-col cols="12" md="6">
                
                
            </v-col>
        </v-row>
        
    </div>
</template>

<script>

import cardata from '@/api/78631.json'

export default {
  name: 'App',
  components: {
    

  },
  data () {
    return {
        CAR: cardata,
        forms: {
            trimcode: '78631',
            ProdType: 'R',
            Period: '60',
            PriceType: '1',
        }
    }
  },
  created() {},
  methods: {
   
  }
}
</script>
