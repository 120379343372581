<template>
  <div>
    <v-tabs
      v-model="tab"
      

      

    >
      <v-tabs-slider></v-tabs-slider>

     
      <v-tab href="#tab-1">
        엠캐피탈-국산
      </v-tab>
      <v-tab href="#tab-2">
        신한카드-리스-국산
      </v-tab>
      <v-tab href="#tab-3">
        다나와
      </v-tab>
      <v-tab href="#tab-4">
        다나와-옵션
      </v-tab>
      
    </v-tabs>

    <v-tabs-items v-model="tab">
        <v-tab-item
            value="tab-1"
        >
            <MCapitalForms />
        </v-tab-item>
        <v-tab-item
            value="tab-2"
        >
            <ShinhanCardForms />
        </v-tab-item>
        <v-tab-item
            value="tab-3"
        >
            <Danawa />
        </v-tab-item>
        <v-tab-item
            value="tab-4"
        >
            <Options />
        </v-tab-item>

      
    </v-tabs-items>
  </div>
</template>

<script>
import ShinhanCardForms from '@/components/ShinhanCardForms'
import Danawa from '@/components/site/Danawa'
import Options from '@/components/site/Options'
import MCapitalForms from '@/components/site/mcapital/MCapitalForms'
  export default {
    name: 'HelloWorld',
    components: {
      ShinhanCardForms,
      Danawa,
      Options,
      MCapitalForms

    },
    data () {
      return {
        items: [
          { 
            name: '기본정보', 
            children:[
              { key:'model', name: '모델명', value: 'mdi-clock', visible: true },
              { key:'prices', name: '차량가격', value: 'mdi-clock', visible: true },
              { key:'prices1', name: '할인금액', value: 'mdi-clock', visible: true },
              { key:'prices2', name: '옵션금액', value: 'mdi-clock', visible: true },
              { key:'prices3', name: '탁송료', value: 'mdi-clock', visible: true },
              { key:'prices4', name: '총차량가격', value: 'mdi-clock', visible: true },
              { key:'prices5', name: '계산서가액', value: 'mdi-clock', visible: true },
              { key:'prices6', name: '배기량', value: 'mdi-clock', visible: true },
              { key:'prices7', name: '내수구분코드', value: 'mdi-clock', visible: true },
              { key:'prices8', name: '전략차종코드', value: 'mdi-clock', visible: true },
              { key:'prices9', name: '차량적재량', value: 'mdi-clock', visible: true },
              { key:'prices10', name: 'IRR분류', value: 'mdi-clock', visible: true },
              { key:'prices11', name: '유종코드', value: 'mdi-clock', visible: true },
              { key:'prices12', name: '공채차종구분', value: 'mdi-clock', visible: true },
              { key:'prices13', name: '인승', value: 'mdi-clock', visible: true },
              { key:'prices14', name: '브랜드', value: 'mdi-clock', visible: true },
            ] 
          },
          {
            name: '기본정보', 
            children:[
              { key:'model', name: '모델명', value: 'mdi-clock' },
              { key:'prices', name: '차량가격', value: 'mdi-clock' },
            ] 
          },
          
          
        ],
        info: 'aaa<br />',
        tab: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      }
    },
  }
</script>
