<template>
    <div>
        <div >
            <v-card class="mt-5">
                <el-cascader
                    style="width:100%"
                    v-model="value"
                    :options="cardb"
                    :props="{ expandTrigger: 'hover' }"
                    placeholder="엠캐피탈의 차량정보를 직접입력하거나 선택하세요"
                    @change="categoryChange" clearable filterable></el-cascader>
            </v-card>
        </div>

        <v-row justify="space-between">
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title>사용자 변경가능 폼</v-card-title>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-expansion-panels v-model="panel" multiple v-if="selectCar">
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    차량정보
                                </v-expansion-panel-header>
                                <v-expansion-panel-content >
                                    <v-row justify="space-between">
                                        <v-col cols="12" md="12">
                                            <v-radio-group
                                                v-model="forms.info.defaultExport"
                                                row
                                                @input="handleChange"
                                                >
                                                <v-radio
                                                    label="특판출고"
                                                    :value="true"
                                                ></v-radio>
                                                <v-radio
                                                    label="지점/대리점출고"
                                                    :value="false"
                                                ></v-radio>
                                                </v-radio-group>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-model="selectCar.kind"
                                                label="차종"
                                                readOnly

                                            ></v-text-field>
                                            
                                            
                                            <v-text-field
                                                v-model.number="forms.info.carPrice"
                                                type="number"
                                            
                                                label="차량기본가격*"
                                                required
                                                suffix="원"
                                                @input="handleChange"
                                            ></v-text-field>

                                            <v-select
                                                v-model="forms.info.deliveryConditions"
                                                :items="formData.deliveryConditions"
                                                item-text="text"
                                                item-value="value"
                                                label="출고조건선택"
                                            ></v-select>
                                            <v-text-field
                                                v-if="forms.info.deliveryConditions==='본사출고'"
                                                v-model.number="forms.info.headOfficePrice"
                                                type="number"
                                                label="본사출고조건"

                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-model.number="selectCar.cc"
                                                type="number"
                                                
                                                label="배기량"
                                                suffix="CC"
                                                readOnly
                                            ></v-text-field>
                                            <v-text-field
                                                v-model.number="forms.info.optionAmount"
                                                type="number"
                                                label="옵션+색상가"
                                                @input="handleChange"
                                                suffix="원"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model.number="forms.info.discountAmount"
                                                type="number"
                                                label="할인금액"
                                                @input="handleChange"
                                                suffix="원"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model.number="forms.info.personalConsumptionTax"
                                                type="number"
                                                label="개별소비세인하액"
                                                @input="handleChange"
                                                suffix="원"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    취득세 설정
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row justify="space-between">

                                        <v-col cols="12" md="12">
                                            <v-radio-group
                                                v-model="forms.info.acquisitionTaxRateType"
                                                row
                                                @change="handleChange"
                                                >
                                                <v-radio
                                                    label="자동반영"
                                                    :value="0"
                                                ></v-radio>
                                                <v-radio
                                                    label="취득세율입력"
                                                    :value="1"
                                                ></v-radio>
                                                <v-radio
                                                    label="감면액입력"
                                                    :value="2"
                                                ></v-radio>
                                                <v-radio
                                                    label="비용입력"
                                                    :value="3"
                                                ></v-radio>
                                                </v-radio-group>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="forms.info.acquisitionTaxRateType>0">
                                            <v-text-field
                                                v-if="forms.info.acquisitionTaxRateType===1"
                                                v-model.number="forms.vehicle.car.customacquisitionTaxRate"
                                                type="number"
                                                label="취득세율 입력하세요"
                                                suffix="%"

                                            ></v-text-field>
                                            <v-text-field
                                                v-if="forms.info.acquisitionTaxRateType===2"
                                                v-model.number="forms.vehicle.car.customacquisitionTaxAmount"
                                                type="number"
                                                label="감면액을 입력하세요"
                                                suffix="원"
                                            ></v-text-field>
                                            <v-text-field
                                                v-if="forms.info.acquisitionTaxRateType===3"
                                                v-model.number="forms.vehicle.car.customacquisitionTaxAmount"
                                                label="취득세를 입력하세요"
                                                type="number"
                                                suffix="원"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    

                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    리스기간
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row justify="space-between">

                                        <v-col cols="12" md="12">
                                            {{forms.info.leaseMonth}}
                                            <v-radio-group
                                                v-model="forms.info.leaseMonth"
                                                row
                                                @change="handleChange"
                                                >
                                                <v-radio
                                                    label="12개월"
                                                    :value="12"
                                                ></v-radio>
                                                <v-radio
                                                    label="24개월"
                                                    :value="24"
                                                ></v-radio>
                                                <v-radio
                                                    label="36개월"
                                                    :value="36"
                                                ></v-radio>
                                                <v-radio
                                                    label="48개월"
                                                    :value="48"
                                                ></v-radio>
                                                <v-radio
                                                    label="60개월"
                                                    :value="60"
                                                ></v-radio>
                                                </v-radio-group>
                                        </v-col>
                                        <v-col cols="12" md="12">
                                            {{forms.info.leaseKm}}
                                            <v-radio-group
                                                v-model="forms.info.leaseKm"
                                                row
                                                @change="handleChange"
                                                >
                                                <v-radio
                                                    label="년2만km"
                                                    :value="20000"
                                                ></v-radio>
                                                <v-radio
                                                    label="년3만km"
                                                    :value="30000"
                                                ></v-radio>

                                                </v-radio-group>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="forms.info.acquisitionTaxRateType>0">
                                            <v-text-field
                                                v-if="forms.info.acquisitionTaxRateType===1"
                                                v-model.number="forms.vehicle.car.customacquisitionTaxRate"
                                                type="number"
                                                label="취득세율 입력하세요"
                                                suffix="%"

                                            ></v-text-field>
                                            <v-text-field
                                                v-if="forms.info.acquisitionTaxRateType===2"
                                                v-model.number="forms.vehicle.car.customacquisitionTaxAmount"
                                                type="number"
                                                label="감면액을 입력하세요"
                                                suffix="원"
                                            ></v-text-field>
                                            <v-text-field
                                                v-if="forms.info.acquisitionTaxRateType===3"
                                                v-model.number="forms.vehicle.car.customacquisitionTaxAmount"
                                                label="취득세를 입력하세요"
                                                type="number"
                                                suffix="원"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    

                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    탁송료
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row justify="space-between">

                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-model="selectCar.consignment"
                                                label="1차출고지"

                                            ></v-text-field>
                                            <v-text-field
                                                v-model="selectCar.consignment"
                                                label="2차탁송"

                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                v-model="selectCar.consignment"
                                                label="1차탁송료"

                                            ></v-text-field>
                                            <v-text-field
                                                v-model="selectCar.consignment"
                                                label="2차 탁송료"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    

                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    공과금항목
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row justify="space-between">
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                
                                                
                                                label="공채할인지역"
                                                value="경기"
                                                readOnly
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            
                                            <v-text-field
                                                v-model.number="forms.dues.publicBondPurchaseRate"
                                                type="number"
                                                label="공채할인율"
                                                @input="handleChange"
                                                suffix="%"
                                            ></v-text-field>
                                            <v-text-field
                                                v-model.number="forms.vehicle.car.outherAmount"
                                                type="number"
                                                label="부대비용포함체크값"
                                                persistent-hint
                                                hint="엑셀에서 해당값입력"
                                            ></v-text-field>
                                        
                                        </v-col>
                                    </v-row>
                                    <v-alert
                                        dense
                                        outlined
                                        type="error"
                                        >
                                        ** 부대비용포함(체크된상태)은 엑셀파일의 신한리스국산에 해당값을 입력
                                        
                                        </v-alert>
                                    <v-alert
                                        dense
                                        outlined
                                        type="error"
                                        >
                                        공채할인지역:경기로 고정(지역에따라 금액변동)<br />
                                        공채가격은 차종,배기량 입력 필수
                                        </v-alert>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            
                        </v-expansion-panels>
                    </v-form>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title>견적결과 내용</v-card-title>
                </v-card>
                <div>
                    본 테스트는 리스원금까지만 산출하도록 처리되었습니다<br />
                    월리스료 및 다른항목의 100% 자동화 처리하기 위해서는<br />
                    기본 차량정보에 탁송료, 공채등 방대한 양의 데이터가 세팅이 되어야 하는 관계로<br />
                    짧은시간안에 처리하기는 어렵습니다<br />
                    * 상단 차량정보 카테고리는 무시해도 됩니다
                </div>
                <br />
                <v-divider />
                <div>
                    <span v-for="(item,i) in resultList" :key="i">{{item}}<br /></span>
                </div>
            </v-col>
        </v-row>
       
    </div>
</template>
<script>
  import cardata from '@/api/mcapitalcategory.json'
  import mcapitalrv from '@/api/mcapital_rv.json'
  export default {
    data () {
        return {
            selectCar: null,
            carRV: mcapitalrv,//잔가표
            cardb: cardata,
            panel: [0, 1, 2],
            valid: true,
            rules: {
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                nameRules: [
                    v => !!v || 'Name is required',
                    v => (v && v.length <= 10) || 'Name must be less than 10 characters',
                ],
            },
            formData: {
                deliveryConditions:['대리점출고'],//], '본사출고'],
                consignmentProcessingMethod:['제조사(딜러탁송]','외주탁송','복합탁송'],
                area: ['경기'],
                carType: [
                    {text:'승용',value:0},
                    {text:'RV(5인이하)',value:1},
                    {text:'RV(7-10인)',value:2},
                    {text:'RV(11인이상)',value:3},
                    {text:'승합',value:4},
                    {text:'화물(1톤이하)',value:5},
                    {text:'화물(2톤이하)',value:6},
                    //{text:'화물(3톤이하)',value:7},
                ],
                
            },
            publicBondPurchaseRateData:[//공공채권 매입율
                { area:'서울', kind: '승용', cc1000: 0.09, cc1500: 0.09, cc1600: 0.12, cc2000:0.2 ,percent: true },
                { area:'서울', kind: 'RV(5인이하)', cc1000: 0.09, cc1500: 0.09, cc1600: 0.12, cc2000:0.2 ,percent: true }
            ],
            publicBondPurchaseRateData2:[//공공채권 매입율
                    {
                        area: '경기', kind: '승용',number: 2,
                        cc: [
                            { percent: true, cc: 1000, value: 0.06 },
                            { percent: true, cc: 1500, value: 0.06 },
                            { percent: true, cc: 1600, value: 0.08 },
                            { percent: true, cc: 2000, value: 0.12 },
                        ]
                    },
                    {
                        area: '경기', kind: 'RV(5인이하)',number: 3,
                        cc: [
                            { percent: true, cc: 1000, value: 0.06 },
                            { percent: true, cc: 1500, value: 0.06 },
                            { percent: true, cc: 1600, value: 0.08 },
                            { percent: true, cc: 2000, value: 0.12 },
                        ]
                    },
                    {
                        area: '경기', kind: 'RV(7-10인)',number: 4,
                        cc: [
                            { percent: true, cc: 1000, value: 0.06 },
                            { percent: true, cc: 1500, value: 0.06 },
                            { percent: true, cc: 1600, value: 0.08 },
                            { percent: true, cc: 2000, value: 0.12 },
                        ]
                    },
                    {
                        area: '경기', kind: 'RV(11인이상)',number: 4,
                        cc: [
                            { percent: true, cc: 1000, value: 0.03 },
                            { percent: true, cc: 1500, value: 0.03 },
                            { percent: true, cc: 1600, value: 0.03 },
                            { percent: true, cc: 2000, value: 0.03 },
                        ]
                    },
                    {
                        area: '경기', kind: '승합',number: 4,
                        cc: [
                            { percent: true, cc: 1000, value: 0.03 },
                            { percent: true, cc: 1500, value: 0.03 },
                            { percent: true, cc: 1600, value: 0.03 },
                            { percent: true, cc: 2000, value: 0.03 },
                        ]
                    },
                    {
                        area: '경기', kind: '화물(1톤이하)',number: 4,
                        cc: [
                            { percent: true, cc: 1000, value: 0.15 },
                            { percent: true, cc: 1500, value: 0.15 },
                            { percent: true, cc: 1600, value: 0.15 },
                            { percent: true, cc: 2000, value: 0.15 },
                        ]
                    },
                    {
                        area: '경기', kind: '화물(2톤이하)',number: 4,
                        cc: [
                            { percent: true, cc: 1000, value: 0.03 },
                            { percent: true, cc: 1500, value: 0.03 },
                            { percent: true, cc: 1600, value: 0.03 },
                            { percent: true, cc: 2000, value: 0.03 },
                        ]
                    }
                ],
            forms: {
                info: {
                    brand: '',//제조사
                    model: '', //차량모델
                    exportType: '', //출고방식 (특판출고, 지점/대리점출고)
                    vehicleOwner: '', // 차량명의(운용: 당사명의, 금융:당사명의,이용자명의,할부.오토론:이용자명의 고정)
                    registerArea: '부산',//등록지, 운용리스는 부산이 고정
                    kind: '',//차종
                    cc: 0, //배기량
                    sdisrate: 0, // 특판할인율
                    isHybride: false, //하이브리드 여부
                    rvfix: 'Y', //고잔가 가능

                    specialAmount: 0, //특판금액 (계산서가격-계산서가포함된탁송료) * 특판할인율

                    acquisitionTaxRateType: 0, //0:자동반영,1:취득세율입력,2:감면액입력,3:금액직접입력

                    defaultExport: true,//본사출고(true), 지점/대리점출고(false)

                    leaseMonth: 12,//리스기간
                    leaseKm: 20000,//약정기간

                    carType: 0,//차종

                    carPrice: 0,//차량가격
                    deliveryConditions:'대리점출고',//출고조건
                    headOfficePrice:0,//본사출고조건
                    optionAmount: 0,//옵션가
                    discountAmount:0,//할인금액
                    personalConsumptionTax:0,//개별소비세 인하액

                },
                consignment: {
                    processingMethod: '제조사(딜러탁송)',//탁송료처리방식
                    area:'',//인도지역
                    amount: 0,//탁송료
                },
                dues: {//공과금
                    publicBondPurchaseRate: 12,//공채할인율-입력자료
                },
                vehicle: {
                    car: {
                        userSmallCarAmout: 0, //이용자명의 경형차량 this.forms.vehicle.bill.finalAmout/1.1 * 0.02
                        totalAmount:0, //최종차량가격
                        supplyPrice:0, //공급가격
                        vta:0,//부가세
                        //consignmentFee: 119000,//제조사탁송료
                        //publicBondPurchaseRate: 12,//공채할인율

                        acquisitionTaxRate:0.07,//bq7: 0.07,//취득세율
                        customacquisitionTaxRate: 0, //취득세율 직접입력시 
                        customacquisitionTaxAmount: 0, //취득세 감면액 또는 취득세 직접입력한 금액
                        acquisitionTax: 0,//취득세
                        finallAcquisitionTax: 0, //최종취득세 - M캐피탈전용
                        publicBondPurchase: 0,//공채가격
                        privateBondPurchaseRate: 0.12,//공채할인율-내부자료

                        

                        fuelCode: 'M',

                        outherAmount: 0,//239100,//부대비용 //todo
            
                        advancePayment: 0,//선수금 //todo
                        leasePrincipal: 0,//리스원금 = 계산서가격합계 + 취득세 + 공채 + 부대비용
                        acquisitionCost: 0, // 취득원가 = 리스원금 + 선수금


                    },
                    bill: { // 계산서가격
                        finalAmout: 0, //최종차량가격 
                        totalAmount:0, //최종차량가격
                        supplyPrice:0, //공급가격
                        vta:0,//부가세
                    },

                }
            },

            resultList: [

            ],
            estimate: {
                brand: '',//제조사
                model: '', //차량모델
                exportType: '', //출고방식 (특판출고, 지점/대리점출고)
                vehicleOwner: '', // 차량명의(운용: 당사명의, 금융:당사명의,이용자명의,할부.오토론:이용자명의 고정)
                registerArea: '부산',//등록지, 운용리스는 부산이 고정
                kind: '',//차종
                cc: 0, //배기량
                sdisrate: 0, // 특판할인율
                hybride: false, //하이브리드 여부
                rvfix: 'Y', //고잔가 가능
                bill: { // 계산서가격
                        // ** 차량 최종가격 산출공식 
                        // =IF(BD7="지점/대리점출고",$BD$15-CM7,ROUNDDOWN($BD$15-CM7,-3))
                        // 지점/대리점출고시 = > 차량가격(계산서가격합계) - 특판할인액
                        // 특판출고시 = > 차량가격(계산서가격합계) - 특판할인액 천원단위 버림=>12345원=>12000원
                        finalAmout: 0, //최종차량가격 
                        totalAmount:0, //게산서 가격 합계
                        supplyPrice:0, //공급가격
                        vta:0,//부가세
                },
                specialAmount: 0, //특판금액 (계산서가격-계산서가포함된탁송료) * 특판할인율
                /*
                // ** 특판할인액 산출하기
                // =IF(BD7="특판출고",CM10*(VLOOKUP(BD6,차량DB!F:AA,22,0)),0)
                // 
                */



            }
       
        }
    },
    methods: {
        categoryChange(value) {
            const lastItem = value[value.length - 1]
            this.$log.warn('lastitem', lastItem)
            this.selectCar = JSON.parse(lastItem)
            this.$log.warn('selectCar', this.selectCar, this.selectCar.kind)
            this.forms.info.kind = this.selectCar.kind
        },
        carTypeChange() {
            const val =  this.publicBondPurchaseRateData[this.forms.info.carType]
            this.forms.vehicle.car.privateBondPurchaseRate = 0
            
            if (this.forms.info.cc>0 && this.forms.info.cc < 1000) {
                
                this.forms.vehicle.car.privateBondPurchaseRate = val.cc[0].value;
            } else if (this.forms.info.cc>=1000 && this.forms.info.cc < 1500) {
                this.forms.vehicle.car.privateBondPurchaseRate = val.cc[1].value;
            } else if (this.forms.info.cc>=1500 && this.forms.info.cc < 1600) {
                this.forms.vehicle.car.privateBondPurchaseRate = val.cc[2].value;
            } else if (this.forms.info.cc>=1600) {
                this.forms.vehicle.car.privateBondPurchaseRate = val.cc[3].value;
            } else {
                this.forms.vehicle.car.privateBondPurchaseRate = 0;
            }
            this.$log.warn('공채할인율-내부자료',  this.forms.vehicle.car.privateBondPurchaseRate);
            this.handleChange();
        },
        groupA() {
            const text = this.selectCar.agroup.replace('군', '');
            //this.carRV['A'][text][this.forms.info.leaseMonth]
            this.$log.warn('A그룹군', this.carRV['A'][text][this.forms.info.leaseMonth] * 0.01) //백분율로 만듬
            // this.resultList.push('typeA 기본--------------------', this.selectCar['month' + this.forms.info.leaseMonth])
        },
        groupB() {
            const text = this.selectCar.bgroup
            //this.$log.warn('B그룹군 시작', text) 
            let bvalue = 0
            let bgroupvalue = 0
            if (text==='별도잔가군') {
                bvalue = this.selectCar['b' + this.forms.info.leaseMonth]
                //this.$log.warn('B그룹군 별도잔가군', this.selectCar['b' + this.forms.info.leaseMonth] * 0.01) //백분율로 만듬
                //return this.selectCar['month' + this.forms.info.leaseMonth] * 0.01
            } 
            if (this.selectCar['b' + this.forms.info.leaseMonth] > 0) {
                if ( text != '' ) {
                    bvalue = this.carRV['B'][text][this.forms.info.leaseMonth]

                    //this.$log.warn('B그룹군', this.carRV['B'][text][this.forms.info.leaseMonth] * 0.01) //백분율로 만듬
                }
            }
            else {
                this.$log.warn('B그룹군 없음') 
            }
            if (bvalue>0) {
                // 약정주행이 2만이면
                if (this.forms.info.leaseKm === 20000) {
                    this.$log.warn('약정주행2만', bvalue)
                    // b60의 값을 가져오고 일반promo값과 더한다
                    bgroupvalue = bvalue + this.selectCar.b60 + this.selectCar.npromo

                }
            }
            this.$log.warn('B그룹군 최종값', bgroupvalue * 0.01) 
            // this.carRV['A'][text][this.forms.info.leaseMonth]
            
            // this.resultList.push('typeA 기본--------------------', this.selectCar['month' + this.forms.info.leaseMonth])
        },
        pmt(ir, np, pv, fv, type) {
            /*
            * ir - 월 이자율
            * np - 기간(개월) 수
            * pv - 현재 가치
            * fv - 미래 가치
            * 유형 - 지불 기한:
            * 0: 마침표의 끝, 예. 월말(기본값)
            * 1: 기간의 시작
            */
        
            let pmt, pvif;

            fv || (fv = 0);
            type || (type = 0);

            if (ir === 0)
                return -(pv + fv)/np;

            pvif = Math.pow(1 + ir, np);
            pmt = - ir * (pv * pvif + fv) / (pvif - 1);

            if (type === 1)
                pmt /= (1 + ir);
            this.$log.warn('pmt', pmt)
            return pmt;
        },
        handleChange() {
            // 8%를 10개월동안 1천만원일경우
            this.pmt(8*0.01/12,10,10000000,0,0)
            this.pmt(5.70*0.01/12,60,-49070200+15000000,9700000-15000000,0)
            this.resultList = [];
            // 특판금액 산출
            this.$log.warn('결과산출')
            // 하이브리드 차량인지 지정하기
            this.forms.info.isHybride = false;
            if (this.selectCar.bigo1==='하이브리드') {
                this.forms.info.isHybride = true;
            }


            this.resultList.push('-----------------차량가격-------------------------')

            this.forms.vehicle.car.totalAmount = this.forms.info.carPrice + this.forms.info.optionAmount - this.forms.info.discountAmount - this.forms.info.personalConsumptionTax;
            this.forms.vehicle.car.supplyPrice = Math.round(this.forms.vehicle.car.totalAmount/1.1);//공급가격
            this.forms.vehicle.car.vta = this.forms.vehicle.car.totalAmount-this.forms.vehicle.car.supplyPrice;//부가세

            this.resultList.push('최종차량가격:' + this.forms.vehicle.car.totalAmount + '원(차량기본가+옵션+색상가-할인가-개소세인하액)');
            this.resultList.push('공급가:' + this.forms.vehicle.car.supplyPrice);
            this.resultList.push('부가세:' + this.forms.vehicle.car.vta);

            this.resultList.push('-----------------계산서가격-------------------------')
            this.forms.vehicle.bill.totalAmount = this.forms.vehicle.car.supplyPrice + this.forms.vehicle.car.vta + this.forms.consignment.amount;//제조사탁송료
            this.forms.vehicle.bill.supplyPrice = Math.round(this.forms.vehicle.bill.totalAmount/1.1);//공급가격
            this.forms.vehicle.bill.vta = this.forms.vehicle.bill.totalAmount-this.forms.vehicle.bill.supplyPrice;//부가세
            
            this.resultList.push('차량가격:'+this.forms.vehicle.bill.totalAmount + '(탁송료포함됨)');
            this.resultList.push('공급가격:'+this.forms.vehicle.bill.supplyPrice);
            this.resultList.push('부가세:'+this.forms.vehicle.bill.vta);

            // 특판인경우 최종가격변동됨

            if (this.forms.info.defaultExport) {

                this.resultList.push('-----------------특판금액-------------------------')
                this.forms.info.sdisrate = this.selectCar.sdisrate
                this.forms.info.specialAmount = Math.round(this.forms.vehicle.car.totalAmount * this.selectCar.sdisrate)
                this.resultList.push('특판금액:', this.forms.info.specialAmount)
                this.$log.warn('특판금액:', this.forms.info.sdisrate, this.forms.info.specialAmount )
                //최종차량가격: 계산서가격-특판금액
                this.forms.vehicle.bill.finalAmout = this.forms.vehicle.bill.totalAmount - this.forms.info.specialAmount
            } else {
                this.forms.info.sdisrate = 0
                this.forms.info.specialAmount = 0
                //최종차량가격: 계산서가격
                this.forms.vehicle.bill.finalAmout = this.forms.vehicle.bill.totalAmount
            }
             this.forms.vehicle.bill.finalAmout = Math.floor(this.forms.vehicle.bill.finalAmout / 1000) * 1000; //백원단위 버림 백원단위 000 처리
            this.resultList.push('최종차량가격:', this.forms.vehicle.bill.finalAmout)
            //this.resultList.push('최종차량가격 대비 공급가:', Math.round(this.forms.vehicle.bill.finalAmout / 1.1))
            // this.resultList.push('최종차량가격 대비 부가세:', this.forms.vehicle.bill.finalAmout)

            //취득세
           
            this.resultList.push('-----------------취득세/공채가격-------------------------')
            // 취득세: Round(최종가격/1.1) * 취득세율
            
            //취득세 계산
            // this.forms.vehicle.car.acquisitionTax = this.forms.vehicle.bill.supplyPrice * this.forms.vehicle.car.acquisitionTaxRate;
            this.forms.vehicle.car.acquisitionTax = Math.round(this.forms.vehicle.bill.finalAmout / 1.1) * this.forms.vehicle.car.acquisitionTaxRate;
           
            this.forms.vehicle.car.acquisitionTax = Math.floor(this.forms.vehicle.car.acquisitionTax / 10) * 10; //원단위 버림
            this.resultList.push('*취득세:' + this.forms.vehicle.car.acquisitionTax)
            //공채1=계산서가격*공채할인율-내부자료 * (공채할인율%)
            this.forms.vehicle.car.publicBondPurchase = this.forms.vehicle.bill.supplyPrice * this.forms.vehicle.car.privateBondPurchaseRate * (this.forms.dues.publicBondPurchaseRate * 0.01)
            //공채2=원단위올림
            this.forms.vehicle.car.publicBondPurchase = Math.ceil(this.forms.vehicle.car.publicBondPurchase / 10) * 10;//원단위 올림
            this.resultList.push('*공채:' + this.forms.vehicle.car.publicBondPurchase)

            this.resultList.push('부대비용:' + this.forms.vehicle.car.outherAmount);
            //리스원금=계산서가격합계 + 취득세 + 공채 + 부대비용
            //취득원가 = 리스원금 + 선수금
            this.resultList.push('-----------------리스원금/취득원가-------------------------')
            this.forms.vehicle.car.leasePrincipal = this.forms.vehicle.bill.totalAmount + this.forms.vehicle.car.acquisitionTax + this.forms.vehicle.car.publicBondPurchase + this.forms.vehicle.car.outherAmount + 5000;
             this.resultList.push('*리스원금:' + this.forms.vehicle.car.leasePrincipal);
            this.forms.vehicle.car.acquisitionCost = this.forms.vehicle.car.leasePrincipal + this.forms.vehicle.car.advancePayment
           
            this.resultList.push('*리스이용금액(취득원가):' + this.forms.vehicle.car.acquisitionCost);

            this.$log.warn('-------------------------------------')
            
            // 취득세가 50만원이하이면 0, 초과이면 취득세-50만원
            if (this.forms.vehicle.car.acquisitionTax>500000) {
                this.resultList.push('-----------------경차 50만원 초과분-------------------------', this.forms.vehicle.car.acquisitionTax-500000)
            }
            //이용자명의(경헝차량)비용
            this.forms.vehicle.car.userSmallCarAmout = this.forms.vehicle.bill.finalAmout/1.1 * 0.02; //이용자명의 경형차량 this.forms.vehicle.bill.finalAmout/1.1 * 0.02
            this.forms.vehicle.car.userSmallCarAmout = Math.floor(this.forms.vehicle.car.userSmallCarAmout / 10) * 10; //원단위 버림
            this.resultList.push('-----------------이용자명의(경헝차량)비용-----', this.forms.vehicle.car.userSmallCarAmout )

            // this.forms.info.isHybride
            // 이용자명의 하이브리드
            // 하이브리드 차량일경우 : 취득세 >= 40만원이면 취득세-40만원 그렇지 않으면 0 //=+IF(AND(CL8="하이브리드", CL15>=400000),CL15-400000,0)
            this.forms.vehicle.car.userHybrideCarAmout = 0;
            if (this.forms.info.isHybride) {
                if (this.forms.vehicle.car.acquisitionTax > 400000) {
                    this.forms.vehicle.car.userHybrideCarAmout = this.forms.vehicle.car.acquisitionTax -400000
                    this.resultList.push('-----------------이용자명의(하이브리드)-------------------------', this.forms.vehicle.car.userHybrideCarAmout)
                }

                
            }
            if (this.forms.vehicle.car.customacquisitionTaxRate == 4) {
                // customacquisitionTaxAmount
                this.forms.vehicle.car.acquisitionTax = this.forms.vehicle.car.customacquisitionTaxAmount

            }
            this.resultList.push('-----------------최종취득세-반영됨------------------------', this.forms.vehicle.car.userHybrideCarAmout)

            this.resultList.push('####################### 최종결과 ##################################')
            this.resultList.push('최종차량가격:', this.forms.vehicle.bill.finalAmout)
            this.resultList.push('부가세:', Math.round((this.forms.vehicle.bill.finalAmout/1.1)/10))

            // finallAcquisitionTax: 0, //최종취득세 - M캐피탈전용
            // 최종 취득세 


            ///리스기간에 따른 typeA B를 찾는다.
            //type-a
            this.resultList.push('----------------- 타입별 금액 찾기------------------')
            this.resultList.push('typeA 기본--------------------', this.selectCar['month' + this.forms.info.leaseMonth])
            
            this.resultList.push('typeB 기본--------------------', this.selectCar['b' + this.forms.info.leaseMonth])
            this.groupA()
            this.groupB()
            /*
            1. 최대잔가율
            // 고잔가=Y 이면 주행거리가감% + 4% 더해주고 고잔가!=Y 이면 주행거리가감값이 최대 잔가율이다
            2. 최대잔가액=차량계산서가격 * 최대잔가율 (원단위 버림)
            3. 최대 잔가율(취득원가대비): 최대잔가액(2) * 최대잔가율 (소수점4자리까지만 이하는 나머지는 버림)

            */

           /*
            const rprice2 = this.forms.info.carPrice;
            this.result.vehiclePrice.totalAmount = rprice2;//차량가격
            this.result.vehiclePrice.supplyPrice = Math.round(rprice2/1.1);//공급가격
            this.result.vehiclePrice.vta = this.result.vehiclePrice.totalAmount-this.result.vehiclePrice.supplyPrice;//부가세
                
                this.resultList.push('-----------------차량가격-------------------------')

            //계산서가
            this.result.billPrice.totalAmount = this.result.vehiclePrice.supplyPrice + this.result.vehiclePrice.vta + this.result.vehiclePrice.consignmentFee;//차량가격
            this.result.billPrice.supplyPrice = Math.round(this.result.billPrice.totalAmount/1.1);//공급가격
            this.result.billPrice.vta = this.result.billPrice.totalAmount-this.result.billPrice.supplyPrice;//부가세

            this.$log.warn('-------------차량가격------------------------')
            this.$log.warn('차량가격',this.result.vehiclePrice.totalAmount);
            this.$log.warn('공급가격',this.result.vehiclePrice.supplyPrice, '---과세반영', this.result.billPrice.supplyPrice);
            this.$log.warn('부가세',this.result.vehiclePrice.vta);
            this.$log.warn('제조사탁송료',this.result.vehiclePrice.consignmentFee);
            this.$log.warn('-------------계산서가------------------------')
            this.$log.warn('차량가격',this.result.billPrice.totalAmount);
            this.$log.warn('공급가격',this.result.billPrice.supplyPrice);
            this.$log.warn('부가세',this.result.billPrice.vta);
            this.$log.warn('result', this.result);
            this.$log.warn('-------------------------------------')
            */

        }
    }
  }
</script>