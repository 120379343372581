<template>
    <div>
        <v-row justify="space-between">
            
            <v-col cols="12" md="6">

                <v-list >
                    {{CAR.name}}
                    
                </v-list>
                <v-divider />
                <v-list  >
                    <v-subheader>세부모델정보</v-subheader>
                    <v-list-item >
                                <v-list-item-content>
                                <v-list-item-title>연료</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ CAR.fuelN}}
                                </v-list-item-action>

                    </v-list-item>
                                        <v-list-item >
                                <v-list-item-content>
                                <v-list-item-title>CC</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ CAR.disp}}
                                </v-list-item-action>

                    </v-list-item>

                    <v-list-item >
                                <v-list-item-content>
                                <v-list-item-title>복합연비</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ CAR.effm}}
                                </v-list-item-action>

                    </v-list-item>
                    <v-list-item >
                                <v-list-item-content>
                                <v-list-item-title>도심연비</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ CAR.effc}}
                                </v-list-item-action>

                    </v-list-item>
                    <v-list-item >
                                <v-list-item-content>
                                <v-list-item-title>고속도로연비</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ CAR.effh}}
                                </v-list-item-action>

                    </v-list-item>
                    <v-list-item >
                                <v-list-item-content>
                                <v-list-item-title>차량가격</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{ CAR.priceAfterTax}}
                                </v-list-item-action>

                    </v-list-item>


                    <v-divider />

                </v-list>
            </v-col>
            <v-col cols="12" md="6">
                
                <v-row justify="center">
                    <v-card-text>트림정보</v-card-text>
                    <v-expansion-panels accordion>
                    <v-expansion-panel v-for="item in CAR.options" :key="item.code">
                        <v-expansion-panel-header>{{item.text}}-옵션가격:{{item.value}}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    </v-expansion-panels>
                </v-row>
            </v-col>
        </v-row>
        
    </div>
</template>

<script>

import cardata from '@/api/options.json'

export default {
  name: 'App',
  components: {
    

  },
  data () {
    return {
        CAR: cardata,
        forms: {
            trimcode: '78631',
            ProdType: 'R',
            Period: '60',
            PriceType: '1',
        }
    }
  },
  created() {},
  methods: {
   
  }
}
</script>
